export const mainPageStat = [
  {
    id: 'quiz',
    text: 'игр выиграли в ЭроКвиз',
    icon: 'QuizIcon',
  },
  {
    id: 'puzzle',
    text: 'побед в пазлах',
    icon: 'PuzzleIcon',
  },
  {
    id: 'tag',
    text: 'побед в пятнашках',
    icon: 'TagIcon',
  },
  {
    id: 'tetris',
    text: 'побед в тетрисе',
    icon: 'TetrisIcon',
  },
];
