import { defineStore } from 'pinia';
import { endpoints } from '@/endpoints';
import Http from '@/Http';

export const useUserStore = defineStore('user', {
  state: () => ({
    trophyMedia: [],
    trophyLinks: [],
    currentPage: 1,
    statistics: {},
    userInfo: {},
    trophyTotal: 0,
    tetrisGirls: [],
    currentSlideIndex: 0,
  }),
  getters: {
    mainPageTrophy: (state) => state.trophyMedia.slice(0, 4),
  },
  actions: {
    async getUserInfo() {
      const result = await Http.get('api/user/profile');
      if (result) {
        this.userInfo = result.data;
      }
    },
    async getStatistics() {
      const result = await Http.get('api/user/statistics');
      if (result) {
        this.statistics = result.data;
      }
    },
    async getTrophy(link = 'api/trophy-photos') {
      const result = await Http.get(link);
      if (result) {
        this.trophyLinks = result.links;
        this.trophyMedia = this.trophyMedia.concat(result.data);
        this.currentPage = result.meta.current_page;
        this.trophyTotal = result.meta.total;
      }
    },
    async getTetrisGirls() {
      const result = await Http.get(endpoints.tetris.girls);
      if (result) {
        this.tetrisGirls = result.data;
      }
    },
    setCurrentSlideIndex(index) {
      this.currentSlideIndex = index;
    },
  },
});
