var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{class:[
    'sidebar',
    { 'mobile-menu-active': _vm.showMenu },
    { 'personal-sidebar-active': _vm.showProfile }
  ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.conversationMode),expression:"!conversationMode"}],ref:"header",staticClass:"sidebar-mobile-top",style:(`position: ${_vm.headerPosition}`)},[(_vm.$route.name === 'home')?_c('button',{staticClass:"personal-toggle js-personal-toggle",on:{"click":_vm.toggleProfile}},[_c('span',{staticClass:"icon-user"})]):_c('button',{staticClass:"personal-toggle js-personal-toggle",on:{"click":function($event){return _vm.goBack()}}},[_c('span',{staticClass:"icon-arrow-small-left"})]),_c('div',{staticClass:"sidebar-mobile-title"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),(_vm.showLevel)?_c('div',{staticClass:"sidebar-mobile-title-caption"},[_vm._v(" Уровень №"+_vm._s(_vm.currentLevel)+" ")]):_vm._e()]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.gameProcess && !_vm.sliderMode),expression:"!gameProcess && !sliderMode"}],staticClass:"nav-mobile",on:{"click":_vm.toggleMenu}},[_vm._m(0)]),(_vm.isMobile && _vm.sliderMode)?_c('a',{staticClass:"btn btn-red btn-download",attrs:{"download":"","href":_vm.currentSlideUrl}},[_c('span',{staticClass:"icon-download"})]):_vm._e()]),_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/images/logo.svg"),"alt":"InEro"}})]),_c('ProfileMenu'),_c('MainMenu'),_c('a',{staticClass:"logout",on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('span',{staticClass:"icon-logout"}),_vm._v("Выйти из аккаунта")]),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nav-mobile-icon"},[_c('span'),_c('span'),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-bottom"},[_c('a',{attrs:{"href":"https://static.beeline.ru/upload/images/politica22102021.pdf"}},[_vm._v("Политика обработки данных")]),_c('a',{attrs:{"href":"https://mob.beeline.ru/subs/inero/regulations"}},[_vm._v("Пользовательское соглашение")])])
}]

export { render, staticRenderFns }