import Vue from 'vue';
import { useAuthStore } from '@/store/auth';
import { useMainStore } from '@/store';
import VueRouter from 'vue-router';
import { routes } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const { isAuth } = useAuthStore();
  useMainStore().hideModal();
  if (!isAuth && to.path !== '/subswait') {
    const login = await useAuthStore().login();
    if (login) {
      next();
    }
  } else {
    next();
  }
});

export default router;
