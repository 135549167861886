<template>
  <div
    :class="[
      'popup',
      'popup-alert',
      { 'active': modalVisible },
      { 'success': modal.success },
      { 'popup-prize': modalType === 'prize' && !fullscreen },
      { 'rules-modal': modalType === 'game' },
      { 'popup-gallery': modalType === 'gallery' },
    ]"
  >
    <component
      :is="ModalComponent"
      v-bind="modal"
      @close="hideModal"
    />
  </div>
</template>

<script>
import { useMainStore } from '@/store';
import { mapState, mapActions } from 'pinia';

const modalMap = {
  confirm: 'ConfirmModal',
  alert: 'AlertModal',
  profile: 'ProfileModal',
  prize: 'PrizeModal',
  slider: 'SliderModal',
  blurred: 'BlurredPrizeModal',
  game: 'GameModal',
  gallery: 'TrophyGalleryModal',
  photo: 'GirlPhotoModal',
  mediaFullscreen: 'MediaFullscreenModal',
};

export default {
  name: 'ModalWrapper',
  computed: {
    ...mapState(useMainStore, ['modalVisible', 'modal', 'fullscreen']),
    modalType() {
      return this.modal.type || 'confirm';
    },
    ModalComponent() {
      return () => import(`@/components/modals/${modalMap[this.modalType]}.vue`);
    },
  },
  methods: {
    ...mapActions(useMainStore, ['hideModal']),
  },
};
</script>

<style lang="scss">
.popup-prize {
  @media (min-width: $screen-lg) {
    left: 360px !important;
    width: calc(100vw - 360px) !important;
  }
  @media (min-width: $screen-lg) {
    left: 423px !important;
    width: calc(100vw - 423px) !important;
  }
}
.game-award-container {
  @media (min-width: $screen-md) {
    height: calc(100vh - 474px);
  }
}
</style>
