import HomeView from '@/views/HomeView.vue';

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { pageTitle: 'Главная', layout: 'default-layout' },
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/AuthView.vue'),
    meta: { layout: 'auth-layout' },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/ChatView.vue'),
    meta: { pageTitle: 'Чаты', layout: 'default-layout' },
    children: [
      {
        path: ':id',
        name: 'conversation',
        component: () => import(/* webpackChunkName: "conversation" */ '@/views/ConversationView.vue'),
        meta: { pageTitle: '', layout: 'default-layout' },
      },
    ],
  },
  {
    path: '/girls',
    name: 'girls',
    component: () => import(/* webpackChunkName: "girls" */ '@/views/GirlsView.vue'),
    meta: { pageTitle: 'Поиск девушки', layout: 'default-layout' },
    children: [
      {
        path: ':id',
        name: 'profile',
        component: () => import(/* webpackChunkName: "girlProfile" */ '@/views/GirlProfileView.vue'),
        meta: { pageTitle: 'Профиль девушки', layout: 'default-layout' },
      },
    ],
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: () => import(/* webpackChunkName: "quiz" */ '@/views/QuizView.vue'),
    meta: { pageTitle: 'ЭроКвиз', layout: 'default-layout' },
  },
  {
    path: '/stat',
    name: 'stat',
    component: () => import(/* webpackChunkName: "stat" */ '@/views/StatView.vue'),
    meta: { pageTitle: 'Статистика', layout: 'default-layout' },
  },
  {
    path: '/casual-games',
    name: 'casual-games',
    component: () => import(/* webpackChunkName: "casual-games" */ '@/views/GameView.vue'),
    meta: { pageTitle: 'Казуальные игры', layout: 'default-layout' },
  },
  {
    path: '/tag',
    name: 'tag',
    component: () => import(/* webpackChunkName: "tag" */ '@/views/TagView.vue'),
    meta: { pageTitle: 'Пятнашки', layout: 'default-layout' },
  },
  {
    path: '/tetris/select',
    name: 'tetris-select',
    component: () => import(/* webpackChunkName: "tetris-select" */ '@/views/TetrisSelectView.vue'),
    meta: { pageTitle: 'Выбор девушки', layout: 'default-layout' },
  },
  {
    path: '/tetris/game',
    name: 'tetris',
    component: () => import(/* webpackChunkName: "tetris-game" */ '@/views/TetrisView.vue'),
    meta: { pageTitle: 'Тетрис на раздевание', layout: 'default-layout' },
  },
  {
    path: '/puzzle',
    name: 'puzzle',
    component: () => import(/* webpackChunkName: "puzzle" */ '@/views/PuzzleView.vue'),
    meta: { pageTitle: 'Пазлы', layout: 'default-layout' },
  },
  {
    path: '/trophy',
    name: 'trophy',
    component: () => import(/* webpackChunkName: "trophy" */ '@/views/TrophyView.vue'),
    meta: { pageTitle: 'Трофеи', layout: 'default-layout' },
  },
  {
    path: '/slider',
    name: 'slider',
    component: () => import(/* webpackChunkName: "trophy" */ '@/views/SliderView.vue'),
    meta: { pageTitle: 'Трофеи', layout: 'default-layout' },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/ErrorView.vue'),
    meta: { layout: 'auth-layout', type: 'serverError' },
  },
  {
    path: '/subswait',
    name: 'subswait',
    component: () => import(/* webpackChunkName: "subswait" */ '@/views/SubswaitView.vue'),
    meta: { layout: 'auth-layout' },
  },
  {
    path: '/subscribe-error',
    name: 'subscribe-error',
    component: () => import(/* webpackChunkName: "subscribe" */ '@/views/SubscribeErrorView.vue'),
    props: true,
    meta: { layout: 'auth-layout' },
  },
  {
    path: '*',
    name: 'error404',
    component: () => import(/* webpackChunkName: "error" */ '@/views/ErrorView.vue'),
    meta: { layout: 'auth-layout' },
  },
];
