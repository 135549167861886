<script lang="jsx">
export default {
  name: 'AppFooter',
  functional: true,
  render(h) {
    return (
      <footer class="footer">
      <a
        target="blank"
        href="https://static.beeline.ru/upload/images/politica22102021.pdf"
      >Политика обработки данных</a>
      <a
        target="blank"
        href="https://mob.beeline.ru/subs/inero/regulations"
      >Пользовательское соглашение</a>
    </footer>
    );
  },
};
</script>
