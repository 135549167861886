export const endpoints = {
  quiz: {
    start: 'api/quiz/attempts',
    answer: 'api/quiz/answer',
    info: 'api/quiz/info',
  },
  tetris: {
    start: 'api/tetris/start',
    nextLevel: 'api/tetris/next-level',
    girls: 'api/tetris/girls',
  },
  tag: {
    start: 'api/tag/start',
    finish: 'api/tag/finish',
  },
  puzzle: {
    start: 'api/puzzle/start',
    finish: 'api/puzzle/finish',
  },
};
