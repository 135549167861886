export const USERNAME_LENGTH = {
  min: 4,
  max: 15,
};

export const QUIZ_TIME = 20;
export const QUIZ_TIME_ENDS = 10;

export const suggestGames = [
  {
    title: 'тетрис на раздевание',
    name: 'tetris',
    buttonText: 'Играть в тетрис',
  },
  {
    title: 'пятнашки',
    name: 'tag',
    buttonText: 'Играть в пятнашки',
  },
  {
    title: 'пазлы',
    name: 'puzzle',
    buttonText: 'Играть в пазлы',
  },
];

// tetris params
export const TETRIS_WIDTH = 16;
export const TETRIS_HEIGHT = 25;
export const TETRIS_SPEED_COEFF = 10;
export const TETRIS_INITIAL_SPEED = 1000;
export const TETRIS_INITIAL_POSITION = 6;
export const TETRIS_LINES_LEVEL = 10;
export const TETRIS_DROP_SPEED = 10;
export const ACTIVE_STATUSES = ['in_progress', 'available'];

// tag params
export const TAG_DIFFICULTY = 4;

// screen sizes
export const screenSizes = {
  mobile: {
    header: 84,
    puzzleHeader: 56,
    footer: 20,
  },
  desktop: {
    header: 155,
    topContent: 80,
  },
};

export const breakpoint = 1230;

export const minPixelsForScroll = 100;

export const slideContentHeightInPercents = 70;

export const trophySliderSizes = {
  slideContentHeightInPercents: 70,
  sliderPaddingInPx: 60,
};
