<template>
  <div
    :class="[
      'sidebar-nav-item-wrapper',
      { 'sidebar-nav-item-group': children.length },
      { 'active': expanded }
    ]"
    @click="children.length ? expanded = !expanded : false"
  >
    <router-link
      :class="[
        'sidebar-nav-item',
        'sidebar-nav-item-link',
        { 'sidebar-nav-group-toggle default-cursor': children.length },
        { 'active': isActive },
        { 'disabled': disabled }
      ]"
      :event="disabled ? '' : 'click'"
      :to="{ path, query: { gameName, link }}"
      @click.native="close"
    >
      <div class="sidebar-nav-item-icon">
        <component :is="iconComponent" />
      </div>
      <div
        :class="[
          'sidebar-nav-item-text',
          { 'expandable-item': children.length },
          { 'active': expanded }
        ]"
      >
        {{ name }}
      </div>
      <AppBadge
        v-if="notificate"
        :amount="notificate"
      />
    </router-link>
    <div
      v-if="children.length"
      class="sidebar-nav-item-group-list"
    >
      <div
        class="sidebar-nav-item-group-links"
        style="padding-top: 0"
      >
        <MenuItem
          v-for="(item, index) in children"
          :key="index"
          v-bind="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppBadge from '@/components/AppBadge.vue';
import { useMainStore } from '@/store';
import { mapActions, mapState } from 'pinia';

export default {
  name: 'MenuItem',
  components: {
    AppBadge,
  },
  props: {
    path: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => 'homeIcon',
    },
    children: {
      type: Array,
      default: () => [],
    },
    notificate: {
      type: Number,
      default: () => 0,
    },
    gameName: {
      type: String,
      default: () => '',
    },
    link: {
      type: String,
      default: () => '',
    },
    disableWhenGameProcess: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['close'],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ['gameProcess']),
    iconComponent() {
      return () => import(`@/assets/icons/${this.icon}.vue`);
    },
    isActive() {
      if (this.$route.name === 'casual-games') {
        return this.$route.query.gameName === this.gameName;
      }
      return this.$route.path === this.path
      || (this.gameName && this.$route.path.includes(this.gameName));
    },
    disabled() {
      return (this.disableWhenGameProcess && this.gameProcess) || this.children.length;
    },
  },
  methods: {
    ...mapActions(useMainStore, ['toggleMenu']),
    close() {
      if (!this.children.length) {
        this.toggleMenu();
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar-nav-item-link {
  display: flex;
  align-items: center;
  &.active {
    border-color: $color-red;
  }
}
.sidebar-nav-item-wrapper {
  margin-top: 12px;
}
.sidebar-nav-item.disabled:not(.active):not(.default-cursor):hover {
  cursor: not-allowed;
  & svg {
    .el-main {
      fill: #777778 !important;
    }
  .el-sub {
    fill: #5D5F61 !important;
    }
  }
}
</style>
