<script lang="jsx">
export default {
  name: 'AppBadge',
  functional: true,
  props: {
    amount: {
      type: Number,
      default: () => 0,
    },
  },
  render(h, { props }) {
    return (
      <div class="badge">{props.amount}</div>
    );
  },
};
</script>
