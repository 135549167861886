var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'popup',
    'popup-alert',
    { 'active': _vm.modalVisible },
    { 'success': _vm.modal.success },
    { 'popup-prize': _vm.modalType === 'prize' && !_vm.fullscreen },
    { 'rules-modal': _vm.modalType === 'game' },
    { 'popup-gallery': _vm.modalType === 'gallery' },
  ]},[_c(_vm.ModalComponent,_vm._b({tag:"component",on:{"close":_vm.hideModal}},'component',_vm.modal,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }