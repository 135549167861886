export const casualGames = {
  tetris: {
    title: 'Тетрис на раздевание',
    description: 'Раздень девушку за 30 шагов',
  },
  tag: {
    title: 'Пятнашки',
    description: 'Собери головоломку и получи фото раздетой девушки',
  },
  puzzle: {
    title: 'Пазлы',
    description: 'Собери пазл и получи обнаженное фото девушки с картинки!',
  },
};
