<template>
  <div class="page-content-wrap">
    <div class="page-content-bg" />
    <div class="page-content">
      <h1 class="page-title">
        {{ pageTitle }}
      </h1>

      <div class="main-grid">
        <div class="main-grid-panel">
          <div class="main-grid-panel-header">
            <div class="main-grid-panel-title">
              Чат с девушками
            </div>
            <router-link
              v-if="chats.length"
              :to="{path: '/girls'}"
              class="btn-red btn-icon"
            >
              <span class="icon-plus" />
            </router-link>
          </div>
          <div class="main-grid-panel-content">
            <div
              v-if="chats.length"
              class="main-chats"
            >
              <ChatCard
                v-for="(item, index) in chats"
                :key="index"
                v-bind="item"
              />
            </div>
            <EmptyPanel
              v-else
              title="Нет истории чатов"
              description="Ты ещё не общался ни с одной девушкой? Скорее ищи себе собеседницу"
              button-text="Найти собеседницу"
              button-icon="icon-plus"
              @callback="$router.push({ name: 'chat' })"
            />
          </div>
        </div>
        <div class="main-grid-panel">
          <div class="main-grid-panel-header">
            <div class="main-grid-panel-title">
              Игры
            </div>
          </div>
          <div class="main-grid-panel-content">
            <div class="main-games">
              <GameCard
                v-for="(item, index) in gameCards"
                :key="index"
                v-bind="item"
              />
            </div>
          </div>
        </div>
        <div class="main-grid-panel">
          <div class="main-grid-panel-header">
            <div class="main-grid-panel-title">
              Статистика
            </div>
            <router-link
              v-if="!emptyStatistics"
              class="main-grid-panel-link"
              to="/stat"
            >
              Подробнее
            </router-link>
          </div>
          <div class="main-grid-panel-content">
            <div
              v-if="!emptyStatistics"
              class="main-stats"
            >
              <StatCard
                v-for="item in mainPageStat"
                :key="item.id"
                v-bind="item"
              />
            </div>
            <EmptyPanel
              v-else
              title="Нет статистики"
              description="Чтобы начать собирать статистику нужно сыграть в какую-нибудь игру. Может ЭроКвиз?"
              button-text="Играть в ЭроКвиз"
              @callback="$router.push({ name: 'quiz' })"
            />
          </div>
        </div>
        <div class="main-grid-panel">
          <div class="main-grid-panel-header">
            <div class="main-grid-panel-title">
              Трофеи
              <span class="main-counter">({{ trophyTotal }})</span>
            </div>
            <router-link
              v-if="mainPageTrophy.length"
              :to="{ name: 'trophy' }"
              class="main-grid-panel-link"
            >
              Подробнее
            </router-link>
          </div>
          <div class="main-grid-panel-content">
            <div
              v-if="mainPageTrophy.length"
              class="main-media"
            >
              <MediaCard
                v-for="(item, index) in mainPageTrophy"
                :key="index"
                v-bind="item"
                :index="index"
                :add-class="'main-media-item'"
              />
            </div>
            <EmptyPanel
              v-else
              title="Ой, у тебя нет фотографий и видео?"
              description="Я бы конечно показала тебя фотографию одну, но... для начала давай сыграем"
              button-text="Играть в пазлы"
              add-class="empty-panel-photo"
              @callback="$router.push('/game/puzzle')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatCard from '@/components/ChatCard.vue';
import GameCard from '@/components/GameCard.vue';
import StatCard from '@/components/StatCard.vue';
import EmptyPanel from '@/components/EmptyPanel.vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/store/user';
import { mainPageStat } from '@/mainPageStat';
import { useChatStore } from '@/store/chats';
import MediaCard from '@/components/MediaCard.vue';

export default {
  name: 'HomeView',
  components: {
    ChatCard,
    GameCard,
    MediaCard,
    StatCard,
    EmptyPanel,
  },
  computed: {
    ...mapState(useUserStore, ['mainPageTrophy', 'chats', 'statistics', 'trophyTotal']),
    pageTitle() {
      return this.$route.meta.pageTitle || '';
    },
    mainPageStat() {
      return mainPageStat.map((item) => {
        const statItem = this.statistics[item.id];
        return {
          ...item,
          count: statItem.count,
        };
      });
    },
    ...mapState(useChatStore, ['chats']),
    trophyMedia() {
      return this.mainPageTrophy.map((item) => ({
        imgSrc: item.photo.url,
        type: item.photo.mime,
      }));
    },
    gameCards() {
      return [
        {
          imgSrc: 'quiz-mobile',
          title: 'Эроквиз',
          link: {
            path: '/quiz',
          },
        },
        {
          imgSrc: 'tetris-mobile',
          title: 'Тетрис на раздевание',
          link: {
            path: '/casual-games', query: { gameName: 'tetris', link: '/tetris/select' },
          },
        },
        {
          imgSrc: 'tag-mobile',
          title: 'Пятнашки',
          link: {
            path: '/casual-games', query: { gameName: 'tag' },
          },
        },
        {
          imgSrc: 'puzzle-mobile',
          title: 'Пазлы',
          link: {
            path: '/casual-games', query: { gameName: 'puzzle' },
          },
        },
      ];
    },
    emptyStatistics() {
      return Object.values(this.statistics).every((item) => item.count === 0);
    },
  },
};
</script>

<style lang="scss">
.main-chats, .main-games {
  max-width: 100vw;
}
.media-item-large{
      grid-column: span 2;
      img, .video {
        height: 150px;
        @media (max-width: $screen-xs) {
          height: auto;
        }
      }
    }
</style>
