<template>
  <aside
    :class="[
      'sidebar',
      { 'mobile-menu-active': showMenu },
      { 'personal-sidebar-active': showProfile }
    ]"
  >
    <div
      v-show="!conversationMode"
      ref="header"
      class="sidebar-mobile-top"
      :style="`position: ${headerPosition}`"
    >
      <button
        v-if="$route.name === 'home'"
        class="personal-toggle js-personal-toggle"
        @click="toggleProfile"
      >
        <span class="icon-user" />
      </button>
      <button
        v-else
        class="personal-toggle js-personal-toggle"
        @click="goBack()"
      >
        <span class="icon-arrow-small-left" />
      </button>
      <div class="sidebar-mobile-title">
        {{ pageTitle }}
        <div
          v-if="showLevel"
          class="sidebar-mobile-title-caption"
        >
          Уровень №{{ currentLevel }}
        </div>
      </div>
      <button
        v-show="!gameProcess && !sliderMode"
        class="nav-mobile"
        @click="toggleMenu"
      >
        <span class="nav-mobile-icon">
          <span />
          <span />
          <span />
        </span>
      </button>
      <a
        v-if="isMobile && sliderMode"
        class="btn btn-red btn-download"
        download
        :href="currentSlideUrl"
      >
        <span class="icon-download" />
      </a>
    </div>
    <router-link
      to="/"
      class="logo"
    >
      <img
        src="../assets/images/logo.svg"
        alt="InEro"
      >
    </router-link>
    <ProfileMenu />
    <MainMenu />
    <a
      class="logout"
      @click.prevent="logout"
    ><span class="icon-logout" />Выйти из аккаунта</a>
    <div class="sidebar-bottom">
      <a href="https://static.beeline.ru/upload/images/politica22102021.pdf">Политика обработки данных</a>
      <a href="https://mob.beeline.ru/subs/inero/regulations">Пользовательское соглашение</a>
    </div>
  </aside>
</template>

<script>
import { useMainStore } from '@/store';
import { useAuthStore } from '@/store/auth';
import { mapActions, mapState } from 'pinia';
import { casualGames } from '@/casualGames';
import Headroom from 'headroom.js';
import { useUserStore } from '@/store/user';
import MainMenu from './main-menu/MainMenu.vue';
import ProfileMenu from './profile-menu/ProfileMenu.vue';

export default {
  name: 'AppNavigation',
  components: {
    MainMenu,
    ProfileMenu,
  },
  data() {
    return {
      headerPosition: 'fixed',
    };
  },
  computed: {
    pageTitle() {
      if (this.$route.name === 'game' && this.$route.params.id) {
        return casualGames[this.$route.params.id].title;
      }
      return this.$route.meta.pageTitle || '';
    },
    showLevel() {
      return this.$route.path.includes('tetris') && this.gameProcess;
    },
    conversationMode() {
      return this.$route.name === 'conversation';
    },
    sliderMode() {
      return this.$route.name === 'slider';
    },
    currentSlideUrl() {
      return this.trophyMedia[this.currentSlideIndex].photo.url;
    },
    ...mapState(useMainStore, ['showMenu', 'showProfile', 'currentLevel', 'gameProcess', 'isMobile']),
    ...mapState(useUserStore, ['trophyMedia', 'currentSlideIndex']),
  },
  mounted() {
    const { header } = this.$refs;
    if (header) {
      const headroom = new Headroom(header);
      headroom.init();
    }
    if (this.isMobile) {
      window.addEventListener('scroll', this.onScroll);
    }
  },
  methods: {
    ...mapActions(useMainStore, ['toggleMenu', 'toggleProfile']),
    ...mapActions(useAuthStore, ['logout']),
    goBack() {
      if (this.$route.name === 'casual-games' && !this.gameProcess) {
        this.$router.push('/');
        return;
      }
      this.$router.back();
    },
    onScroll() {
      // меняем позишн хедера чтобы не прятался при оверскролле
      if (window.pageYOffset <= 0) {
        this.headerPosition = 'absolute';
      } else {
        this.headerPosition = 'fixed';
      }
    },
  },
};
</script>

<style lang="scss">
.btn-download {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  position: absolute !important;
  right: 30px;
  top: 50%;
  margin-top: -20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  border-radius: 10px !important;
  padding: 0 !important;
  @media (max-width: $screen-xs) {
    right: 12px;
  }
}
</style>
