<template>
  <div class="page-wrap">
    <AppNavigation />
    <slot />
  </div>
</template>

<script>
import AppNavigation from '@/components/AppNavigation.vue';

export default {
  name: 'DefaultLayout',
  components: {
    AppNavigation,
  },
};
</script>
