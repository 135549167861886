<script lang="jsx">

export default {
  name: 'ChatCard',
  functional: true,
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    intelocutor: {
      type: Object,
      default: () => {},
    },
    unreadMessages: {
      type: Number,
      default: () => 0,
    },
    imgUrl: {
      type: String,
      default: () => '',
    },
  },
  render(h, { props }) {
    const badge = props.unreadMessages ? <div class="chat-item-messages">{props.unreadMessages}</div> : '';
    return (
      <div class="main-chats-col">
        <router-link
          to={`/chat/${props.intelocutor.id}`}
          class="main-chat-item"
        >
          <div class="main-chat-item-img">
          { badge }
          <img style="width: 100%" src={props.imgUrl} alt={props.intelocutor.name}
              loading="lazy"/>
            </div>
          <div class="main-chat-girl">
            <div class="main-chat-girl-name">{props.intelocutor.name}, {props.intelocutor.age}</div>
            <div class="main-chat-girl-post">{props.intelocutor.profession}</div>
          </div>
        </router-link>
        <router-link
          to={`/chat/${props.intelocutor.id}`}
          class="main-chat-item-link"
        >Открыть чат</router-link>
      </div>
    );
  },
};
</script>
