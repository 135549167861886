<script lang="jsx">

export default {
  name: 'GameCard',
  functional: true,
  props: {
    imgSrc: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    link: {
      type: Object,
      default: () => {},
    },
  },
  render(h, { props }) {
    // eslint-disable-next-line import/no-dynamic-require
    const img = require(`@/assets/images/${props.imgSrc}.png`);
    return (
      <router-link
        to={props.link}
        class="main-game-item"
      >
        <picture class="main-game-item-img">
          <img src={img} alt={props.title}
                loading="lazy"/>
        </picture>
        <div class="main-game-item-title">
          <span>{props.title}</span>
          <span class="icon-arrow-right" />
        </div>

      </router-link>
    );
  },
};
</script>
