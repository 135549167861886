<script lang="jsx">
export default {
  name: 'EmptyPanel',
  functional: true,
  props: {
    title: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
    buttonText: {
      type: String,
      default: () => '',
    },
    buttonIcon: {
      type: String,
      default: () => '',
    },
    addClass: {
      type: String,
      default: () => '',
    },
  },
  render(h, { props, listeners }) {
    const classes = [
      'empty-panel',
      props.addClass,
    ];
    return (
      <div class={classes}>
        <div class="empty-panel-content">
          <div class="empty-panel-title">{props.title}</div>
            <div class="empty-panel-text">{props.description}</div>
              <a class="btn btn-red" onClick={listeners['callback']}>
                {
                  props.buttonIcon && <span class={props.buttonIcon} />
                }
                {props.buttonText}
              </a>
          </div>
      </div>
    );
  },
};
</script>

<style lang="scss">
  .empty-panel{
    width: 100%;
    flex: 1;
    background: #1E2124 url('@/assets/images/empty-bg.jpg') 50% 50% no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    backdrop-filter: blur(12px);

    &.empty-panel-photo{
      background-image: url('@/assets/images/empty-bg-2.jpg');

      .empty-panel-content{
        max-width: 500px;
      }
    }

    &-content{
      padding: 40px 30px;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      text-align: center;
    }

    &-title{
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 8px;

      @media (max-width: $screen-xs) {
        font-size: 18px;
      }
    }
    &-text{
      font-weight: 500;
      font-size: 20px;
      @media (max-width: $screen-xs) {
        font-size: 14px;
      }
    }
    .btn{
      width: 100%;
      margin-top: 32px;

      .icon-plus{margin-right: 15px;font-size: 14px;}
    }

  }
</style>
