<template>
  <div
    id="app"
    class="app"
  >
    <component :is="layout">
      <router-view />
    </component>
    <ModalWrapper v-if="modalVisible" />
    <ScreenOrientationAlert v-if="showOrientationAlert" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import ScreenOrientationAlert from '@/components/ScreenOrientationAlert.vue';
import { useMainStore } from '@/store';
import { breakpoint } from '@/constants';
import { blockScroll } from '@/helpers';

export default {
  name: 'App',
  components: { ModalWrapper, ScreenOrientationAlert },
  data() {
    return {
      showOrientationAlert: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ['modalVisible', 'modal', 'fullscreen', 'isMobile', 'showProfile']),
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    fullscreen(val) {
      if (this.isMobile) {
        blockScroll(val);
      }
    },
    modalVisible(val) {
      if (this.isMobile) {
        blockScroll(val);
      }
    },
    showProfile(val) {
      blockScroll(val);
    },
  },
  mounted() {
    this.detectDeviceType();
    if ('ontouchstart' in document.documentElement) {
      const { orientation } = window;
      if (orientation !== 0) {
        this.showOrientationAlert = true;
      }
      window.addEventListener('orientationchange', this.handleOrientationChange);
      document.addEventListener('gesturestart', (event) => {
        event.preventDefault();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  },
  methods: {
    ...mapActions(useMainStore, ['showModal', 'hideModal', 'startGame', 'setDeviceAsDesktop']),
    handleOrientationChange() {
      const { orientation } = window;
      if (orientation === 0) {
        this.showOrientationAlert = false;
      } else {
        this.showOrientationAlert = true;
      }
    },
    detectDeviceType() {
      const dimension = document.documentElement.clientWidth;
      if (dimension > breakpoint) {
        this.setDeviceAsDesktop();
      }
    },
  },
};
</script>

<style lang="scss">
body {
  user-select: none !important;
  touch-action: none;
}
.app {
  min-width: 100%;
  margin: 0;
  min-height: -webkit-fill-available;
  line-height: 1.5;
  padding: 0;
  background: #181A1D;
  font-size: 14px;
  color: $color-white;
  font-family: "Manrope", "Verdana", sans-serif;
  font-weight: 500;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.layout {
  display: flex;
  flex: 1;
}
.form-group{
    position: relative;

    .form-tooltip{
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      transition: opacity .2s ease-out;
      opacity: 0;
      pointer-events: none;
    }

    input.error ~ .form-tooltip, input.error ~ .tooltip-content {
      opacity: 1;
      pointer-events: auto;
    }
  }
.file-input {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
}
// пытаемся запретить ненужное выделение чего-либо
img, .btn {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}
.service-buttons {
  position: absolute;
  z-index: 100000;
}
.fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background:
    linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
  backdrop-filter: blur(10px);
  & img, video {
    max-height: none !important;
    object-fit: contain !important;
    height: 100% !important;
    border-radius: unset !important;
  }
}
</style>
