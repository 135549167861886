<script lang="jsx">
export default {
  name: 'ScreenOrientationAlert',
  functional: true,
  render(h) {
    const imgSrc = require('@/assets/images/mobile_alt.svg');
    return (
      <div class={'popup-semi-transparent'}>
        <div class={'screen-orientation'}>
          <div class={'screen-orientation__image'}>
            <span class="icon-refresh" />
            <img src={imgSrc}/>
          </div>
          <div class={'screen-orientation__title'}>Упс!</div>
          <div class={'screen-orientation__description'}>
            Кажется наш интерфейс не предусмотрен для горизонтального вида, верните ваш телефон в вертикальное положение
          </div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="scss">
.popup-semi-transparent {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(24, 26, 29, 0.95);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  left: 0;
  top: 0;
  z-index: 1000;
}
.screen-orientation {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 24px;
  &__image {
    position: relative;
    & span {
      position: absolute;
      right: 0;
      font-size: 20px;
      color: $color-red;
    }
  }
  &__title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: bold;
  }
  &__description {
    max-width: 400px;
    text-align: center;
    font-size: 15px;
  }
}
</style>
