<template>
  <div class="sidebar-content">
    <UserInfo />
    <nav class="sidebar-nav">
      <MenuItem
        v-for="(item, index) in menuItems"
        :key="index"
        v-bind="item"
        @close="toggleMenu"
      />
      <div class="sidebar-nav-footer"><AppFooter /></div>
    </nav>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useMainStore } from '@/store/index';
import { menuItems } from './menuItems';
import MenuItem from './MenuItem.vue';
import UserInfo from '../UserInfo.vue';
import AppFooter from '../AppFooter.vue';

export default {
  name: 'MainMenu',
  components: { MenuItem, UserInfo, AppFooter },
  emits: ['showProfile', 'close'],
  computed: {
    menuItems() {
      return menuItems;
    },
  },
  methods: {
    ...mapActions(useMainStore, ['toggleMenu']),
  },
};
</script>
