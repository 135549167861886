var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'sidebar-nav-item-wrapper',
    { 'sidebar-nav-item-group': _vm.children.length },
    { 'active': _vm.expanded }
  ],on:{"click":function($event){_vm.children.length ? _vm.expanded = !_vm.expanded : false}}},[_c('router-link',{class:[
      'sidebar-nav-item',
      'sidebar-nav-item-link',
      { 'sidebar-nav-group-toggle default-cursor': _vm.children.length },
      { 'active': _vm.isActive },
      { 'disabled': _vm.disabled }
    ],attrs:{"event":_vm.disabled ? '' : 'click',"to":{ path: _vm.path, query: { gameName: _vm.gameName, link: _vm.link }}},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"sidebar-nav-item-icon"},[_c(_vm.iconComponent,{tag:"component"})],1),_c('div',{class:[
        'sidebar-nav-item-text',
        { 'expandable-item': _vm.children.length },
        { 'active': _vm.expanded }
      ]},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(_vm.notificate)?_c('AppBadge',{attrs:{"amount":_vm.notificate}}):_vm._e()],1),(_vm.children.length)?_c('div',{staticClass:"sidebar-nav-item-group-list"},[_c('div',{staticClass:"sidebar-nav-item-group-links",staticStyle:{"padding-top":"0"}},_vm._l((_vm.children),function(item,index){return _c('MenuItem',_vm._b({key:index},'MenuItem',item,false))}),1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }