<template>
  <div class="personal-sidebar">
    <div class="personal-sidebar-header">
      <div class="personal-sidebar-header-title">
        Профиль
      </div>
      <button
        class="personal-toggle js-personal-toggle"
        @click="toggleProfile"
      >
        <span class="icon-close" />
      </button>
    </div>

    <UserInfo />

    <a
      href=""
      class="logout"
      @click.prevent="logout"
    ><span class="icon-logout" />Выйти из аккаунта</a>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useMainStore } from '@/store';
import { useAuthStore } from '@/store/auth';
import UserInfo from '../UserInfo.vue';

export default {
  name: 'ProfileMenu',
  components: {
    UserInfo,
  },
  emits: ['close'],
  methods: {
    ...mapActions(useMainStore, ['toggleProfile']),
    ...mapActions(useAuthStore, ['logout']),
  },
};
</script>
