// eslint-disable-next-line
import { useAuthStore } from '@/store/auth';
import router from '@/router';

const subscribeErrors = {
  'Unsubscribed: insufficient funds':
    'На балансе не хватает денег, чтобы подключить InEro. Пополните баланс и повторите попытку.',
  'Unsubscribed: subscription failed':
    'InEro не подключён. Попробуйте ещё раз позднее или обратитесь в поддержку по бесплатному номеру 8 800 700-06-11.',
};

class Http {
  baseURL = process.env.VUE_APP_API_URL;

  serviceMode = process.env.VUE_APP_SERVICE_MODE;

  async fetchData(method, endpoint, data = null, type = null) {
    const url = this.baseURL + endpoint;
    const headers = new Headers();

    if (!type) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('Accept', 'application/json');
    const { token } = useAuthStore();
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
    const options = {
      method,
      headers,
    };
    if (data) {
      options.body = type === 'formData' ? data : JSON.stringify(data);
    }
    try {
      const response = await fetch(url, {
        ...options,
      });
      if (response.status >= 500 || response.status === 429) {
        router.push('/error');
        return false;
      }
      if (response.status === 422) {
        const errorText = await response.json();
        return {
          error: true,
          errorText,
        };
      }
      if (response.status === 401) {
        useAuthStore().logout();
      }
      if (response.status === 403) {
        const result = await response.json();
        const errorMessage = result.message;
        if (errorMessage && Object.keys(subscribeErrors).includes(errorMessage)) {
          router.push({
            name: 'subscribe-error',
            params: {
              message: subscribeErrors[errorMessage],
            },
          });
        } else {
          window.location.replace(process.env.VUE_APP_AUTH_URL);
        }
      }
      if (response.status < 200 || response.status >= 300) {
        return false;
      }
      const result = await response.json();
      if (this.serviceMode) {
        console.log(method, endpoint, data, result);
      }
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async get(endpoint) {
    const result = await this.fetchData('GET', endpoint, null);
    return result || false;
  }

  async post(endpoint, data = null, type = null) {
    const result = await this.fetchData('POST', endpoint, data, type);
    return result || false;
  }
}

export default new Http();
