<template>
  <div class="page-wrap">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'AuthLayout',
};
</script>
