export const pluralize = (num, arr) => {
  const tale = num % 10;
  if (tale === 1) {
    return arr[0];
  }
  if (tale >= 2 && tale <= 4) {
    return arr[1];
  }
  return arr[2];
};
export const getRandomTime = () => {
  const rand = 5000 - 0.5 + Math.random() * (15000 - 5000 + 1);
  return Math.round(rand);
};

export const blockScroll = (val) => {
  if (val) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
};

export function debounce(func, wait) {
  let timeout;
  const calls = {};
  return function () {
    calls.previousCall = calls.lastCall;
    calls.lastCall = Date.now();
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (calls.previousCall && calls.lastCall - calls.previousCall <= wait) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}
