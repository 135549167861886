<template>
  <div
    class="main-stats-panel"
  >
    <div class="main-stats-icon">
      <component :is="iconComponent" />
    </div>
    <div class="main-stats-value">
      {{ count }}
    </div>
    <div class="main-stats-text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    text: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => '',
    },
    count: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    iconComponent() {
      return () => import(`@/assets/icons/${this.icon}.vue`);
    },
  },
};
</script>

<style lang="scss">
.main-stats-panel {
  .el-main {
    fill: #fff;
  }
  .el-sub {
    fill: #F4A6AF;
  }
}

</style>
